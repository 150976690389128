@import "../../styles/helpers";

.header {
    position: relative;
    z-index: 20;
    padding: 18px 0;
    border-bottom: 1px solid $neutrals2;
    @include m {
        padding: 48px 0 24px;
        border: none; }
    @include dark {
        border-color: #1e1f24; } }

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .button {
        margin-right: 12px;
        @include m {
            display: none; } } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 0;
    margin-right: 10px;
    img {
        width: auto;
        height: 52px;
        margin-right: 20px; } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
    max-width: 380px;
    width: 100%;
    @include m {
        display: none; }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-right: auto;
    border-left: 2px solid $neutrals6;
    @include d {
        display: none; }
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }
    @include dark {
        border-color: $neutrals3; } }

.link {
    margin-left: 32px;
    @include dm-sans;
    line-height: 40px;
    color: $neutrals4;
    transition: color .2s;
    @include m {
        margin-left: 0;
        font-size: 24px;
        line-height: 64px; }
    &:hover,
    &.active {
        color: $blue; }
    &:nth-child(n+3) {
        display: none;
        @include m {
            display: block; } } }

.search {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 24px;
    @include t {
        display: none; }
    @include m {
        display: block;
        width: 100%;
        margin: auto 0 0; } }

.input {
    width: 100%;
    height: 40px;
    padding-left: 14%;
    background-color: $lightDark;
    color: white;
    border-radius: 20px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    left: calc(12% - 38px);
    bottom: 0;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } } }

.buttonWrapper {
    display: flex;
    align-items: center;
    .profileButton {
        margin-right: 40px;
        color: whitesmoke; }
    .profileButton:hover {
        color: white; }
    .primaryButton {
        font-size: 15px;
        padding: 5px 20px; } }

.mobileContainer {
    display: none;
    margin-top: 10px;
    @include m {
        display: block; }
    .wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        max-width: 380px;
        width: 100%;
        margin: auto; } }
