.app-home-page {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // max-width: 1180px;
  align-content: center;
  background: linear-gradient(180deg, transparent 0, #141414 584px), linear-gradient(180deg, transparent 0%, rgba(14, 14, 14, 1) 900px), linear-gradient(180deg, #2e2e2e 0%, transparent 700px), #141414;

  .home-content {
    padding-bottom: 30px;
    box-sizing: border-box;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    width: 100%;
    background-color: #141414;
  }
}

.content {
  width: 100%;
  // max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0;

  @media only screen and (max-width: 1120px) {
    // padding: 0 20px;
  }
}

.app-layouts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;


  @media only screen and (max-width: 990px) {
    justify-content: center;
  }
}

.layout-info-wrapper {
  display: flex;
  justify-content: center;
}

.layout-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 1280px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

