.app-button {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 12px 24px;
  border-radius: 5px;
  background: #0efba8;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    width: 100%;
  }

  &.small {
    font-size: 12px;
    line-height: 14px;
  }

  &.medium {
    font-size: 16px;
    line-height: 19px;
  }

  .button-icon {
    height: 15px;
    background: rgba(255, 255, 255, 0);
  }
}

.app-button-no-money {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 12px 24px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    width: 100%;
  }

  &.small {
    font-size: 12px;
    line-height: 14px;
  }

  &.medium {
    font-size: 16px;
    line-height: 19px;
  }

  .button-icon {
    height: 15px;
    background: rgba(255, 255, 255, 0);
  }
}
