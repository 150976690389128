.app-derivatives-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;

  .derivatives-detail {
    width: 100%;
    min-width: 200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    box-sizing: border-box;

    .derivatives-layout-title {
      display: flex;
      align-items: center;

      &.history {
        margin-bottom: 18px;
      }

      .derivatives-title {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin: 0;
        margin-right: 8px;
      }
    }

    .history-container {
      margin: 8px 0 10px;

      .history-description {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        line-height: 16px;
        margin: 0;
        margin-bottom: 7px;

        strong {
          font-weight: 600;
        }
      }

      .history-date {
        color: rgba(255, 255, 255, 0.25);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        margin: 0;
      }
    }
  }
}
