.app-creator-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    justify-content: center;
  }

  .creator-details {
    width: 100%;
    min-width: 310px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    box-sizing: border-box;

    @media only screen and (max-width: 600px) {
      width: 100%;
      min-width: 340px;
    }

    .description {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 18px;
    }

    .showmore-button {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
