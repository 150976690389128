.app-profile-info-layout {
  max-width: 779px;
  margin: 0 auto 24px;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;

  .profile-avatar-container {
    width: 180px;

    .profile-avatar {
      width: 100%;
      padding: 10px;
      margin-bottom: 12px;
      box-sizing: border-box;

      img {
        width: 100%;
        border-radius: 150px;
        height: 150px;
        width: 150px;
        object-fit: cover;
      }
    }

    .profile-button {
      margin-top: 12px;
      background: rgba(255, 255, 255, 0.08);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      letter-spacing: 0.02em;
      font-weight: 600;
      align-items: center;
    }
  }

  .profile-details {
    width: calc(100% - 200px);
    padding-top: 35px;

    .profile-name-container {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .profile-name {
        color: white;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        margin: 0;
        margin-right: 12px;
      }

      .badge-icon {
        width: 32px;
      }
    }

    .profile-description-container {
      .profile-reviews {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .app-text-label {
          margin-right: 24px;
        }
      }

      .profile-description {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    .profile-avatar-container {
      width: 100%;
      max-width: 200px;
    }

    .profile-details {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.profile-details {
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 728px) {
.profile-name {
    font-size: 28px !important;
  }
}