@import "../../styles/helpers";

.banner {
  position: relative;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover; }
  .user {
    display: flex;
    margin-top: -60px;
    padding: 0 80px;
    @include t {
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem); }
    img {
      width: 80px;
      border: 2px solid white;
      border-radius: 50%; }
    span {
      font-weight: bold;
      font-size: 30px; } } }

.divider {
  height: 8vh;
  border-bottom: 1px solid $border;
  padding-top: 15px;
  margin-left: 50px;
  margin-right: 50px;
  .menuWrapper {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 80px;
    span {
        font-weight: 500;
        margin-right: 40px;
        cursor: pointer;
        font-size: 15px;
        &.active {
            color: $primary; } }
    @include t {
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem); } } }

.container {
  padding: 0 80px 50px;
  @include t {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem); } }
