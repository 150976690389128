.app-buy-layout {
  min-width: 380px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .price-label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  .price-sol {
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 64px;
    letter-spacing: 0.04em;
    margin: 0;
    display: flex;
    align-items: flex-end
  }

  .copies-available {
    background-color: rgba(255,255,255,0.1);
    font-weight: 600;
    color: rgba(255,255,255,0.8);
    border-radius: 0.5em;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 9px;
    line-height: 30px;
  }

  .token-supply {
    display: flex;
    align-items: flex-end
  }

  .copies-available {
    background-color: rgba(255,255,255,0.1);
    font-weight: 500;
    color: rgba(255,255,255,0.8);
    padding: 0.3em .8em;
    border-radius: 0.25em;
    margin-right: 1em;
    margin-bottom: 1.2em;
  }

  .price-usd {
    color: rgba(255, 255, 255, 0.25);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    margin: 0;
    margin-bottom: 15px;
  }

  .price-reep {
    color: rgba(255, 255, 255, 0.8);
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.04em;
    margin: 0;
    margin-bottom: 15px;
  }
}
