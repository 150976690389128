@import "../../../styles/helpers";

.container {
    padding: 0 15px;
    margin-bottom: 40px;
    justify-content: end;
    align-items: end;
    @include a {
        max-width: 350px;
        margin: 0 auto;
        margin-bottom: 40px; } }

.imageWrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    .image {
        position: absolute;
        width: 100%;
        border-radius: 8px;
        height: 100%;
        object-fit: cover; } }

.title {
    font-size: 15px;
    font-weight: bold;
    padding: 8px 0 0 5px; }

.price {
    font-size: 15px;
    font-weight: bold;
    padding-left: 5px; }

.status {
    p {
        font-size: 10px;
        color: rgba(255,255,255,0.7);
        line-height: 1.3;
        padding-left: 5px; } }

.buttonContainer {
    background-color: $primary;
    margin-left: 5px;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 25px;
    padding: 3px;
    button {
        width: 100%;
        font-size: 12px;
        color: black;
        padding: 0;
        font-weight: bold; } }

.buttonInactive {
    background-color: $neutrals2;
    margin-left: 5px;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 25px;
    padding: 3px;
    color: white;
    button {
        width: 100%;
        font-size: 12px;
        color: grey;
        padding: 0;
        font-weight: bold; } }
