.app-nft-card {
  width: 260px;
  height: 120px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .nft-asset {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }

  .nft-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;

    .nft-category {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .category-name {
        color: #A0A0A0;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin: 0;
        margin-right: 6px;
      }
    }

    .nft-title {
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }

    .price-label {
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      text-align: right;
      margin: 0;
      margin-bottom: 4px;
    }

    .price-value {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      margin: 0;
    }
  }
}
