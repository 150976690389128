.app-derivatives-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;

  .derivatives-detail {
    width: 100%;
    min-width: 200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    box-sizing: border-box;

    .derivatives-layout-title {
      display: flex;
      align-items: center;

      .derivatives-title {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin: 0;
        margin-right: 8px;
      }
    }

    .derivatives-cards {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    .showmore-button {
      margin-top: 12px;
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    .derivatives-detail {
      width: 100% !important;
      .derivatives-cards {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
