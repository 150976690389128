.app-streaming-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;

  .streaming-status {
    width: 100%;
    min-width: 200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    box-sizing: border-box;

    .streaming-layout-title {
      display: flex;
      align-items: center;

      .streaming-title {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin: 0;
        margin-right: 8px;
      }
    }

    .streaming-cards {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      @media only screen and (max-width: 768px) {
        display: block;
      }
    }

    .line-chart {
      width: 100%;
      margin-bottom: 30px;
    }

    .streaming-popular {
      padding: 12px 12px 20px;
      background: rgba(255, 255, 255, 0.02);
      border-radius: 4px;
      box-sizing: border-box;

      .popular-label {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin: 0;
      }

      .popular-card {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        &.half {
          width: calc(50% - 18px);
          min-width: 200px;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    .streaming-status {
      width: 100%;
    }
  }
}
