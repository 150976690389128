.app-music-container {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 1280px;
  display: flex;
}

.loading-app-music-container {
  padding-top: 40px;
  padding-bottom: 20px;
  width: 1280px;
  display: flex;
}

.hero-blur {
  width: 100%;
  backdrop-filter: blur(40px);
}

.app-music-info {
  width: calc(100% - 38px);
  margin-bottom: 60px;
  box-sizing: border-box;

  @media only screen and (max-width: 990px) {
    width: 100%;
    padding: 12px 20px;
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
  }

  .badge-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .badge-title {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      margin: 0;
      margin-right: 8px;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .music-info-wrapper {
    margin-bottom: 60px;
  }

  .music-title {
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    margin: 0;
    min-height: 60px;
  }

  .music-chart {
    width: 100%;
    margin-bottom: 21px;
  }
}

.layout-hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.layout-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.hero-blur {
  width: 100%;
  backdrop-filter: blur(40px);
  display: flex;
  justify-content: center;
  align-items: center;
}
