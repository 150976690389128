.app-post-card {
  padding: 16px 20px;
  margin-bottom: 23px;
  background: #1A1A1A;
  border-radius: 4px;
  box-sizing: border-box;

  .post-reviews {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);

    .review-item {
      padding: 0 10px;
      margin-right: 20px;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      .review-value {
        color: #585858;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &.simple {
    display: flex;

    .thumbnail-image {
      width: 108px;
      margin-right: 20px;
      border-radius: 6px;
    }

    .post-details {
      .post-title {
        display: flex;
        align-items: center;
        color: #A0A0A0;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin: 8px 0;

        .badge-icon {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }
      }

      .comment-text {
        color: #FFFFFF;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        margin-bottom: 16px;
      }

      .post-reviews {
        height: auto;
        margin-bottom: 0;
        border-bottom: none;

        .review-item {
          padding: 10px;

          .review-value {
            margin: 0;
          }
        }
      }
    }
  }

  &.full {
    background: #191919;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    .post-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .post-author {
        display: flex;

        .avatar-img {
          width: 48px;
          height: 48px;
          border-radius: 100px;
          object-fit: cover;
          margin-right: 16px;
        }

        .post-author-detail {
          .author-name {
            color: #FFFFFF;
            font-size: 11px;
            line-height: 13px;
            margin: 8px 0;

            strong {
              font-size: 14px;
              line-height: 16px;
              font-weight: 600;
            }
          }

          .post-title {
            color: rgba(255, 255, 255, 0.5);
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            padding: 4px 12px;
            background: rgba(255, 255, 255, 0.08);
            border-radius: 59px;
            box-sizing: border-box;
          }
        }
      }

      .dot-menu {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }

    .post-body {
      width: 100%;

      .post-image-container {
        width: 100%;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;

        .locked {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .post-image {
          width: 100%;
          visibility: hidden;
        }

        .lock-message {
          color: white;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          margin: 0;
          margin-top: 16px;
        }
      }
    }

    .comment-input-box {
      width: 100%;
      height: 36px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.02);
      border-radius: 50px;
      box-sizing: border-box;

      .comment-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }

      .comment-input {
        color: white;
        font-family: 'Work Sans', sans-serif;
        font-size: 14px;
        line-height: 16px;
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        /* WebKit, Blink, Edge */
        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        /* Mozilla Firefox 19+ */
        &::-moz-placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        /* Microsoft Edge */
        &::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        /* Most modern browsers support this now. */
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
