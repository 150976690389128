.app-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;

  .divider-line {
    width: calc(50% - 21px);
    height: 1px;
    background-color: rgba(255, 255, 255, 0.04);
  }

  .divider-label {
    color: rgba(255, 255, 255, 0.08);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
}
