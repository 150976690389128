@import "../../../styles/helpers";

.container {
    min-height: 400px; }
.label {
    font-size: 12px;
    color: white;
    opacity: 0.4;
    text-transform: uppercase; }
.priceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
        padding: 8px 15px;
        width: calc(50% - 20px); }
    p {
        min-width: 40px;
        text-align: center; } }
.sliderPrice {
    display: flex;
    justify-content: space-between;
    align-items: center; }
.divider {
    height: 1px;
    width: 100%;
    background-color: $border; }
.searchWrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
    width: 100%;
    //+d
    //    flex-grow: 0
    //+m
    //    position: absolute
    //    top: 0
    //    left: 0
    //    right: 0
    //    flex-direction: column
    //    align-items: stretch
    //    height: 100vh
    //    margin: 0
    //    padding: 140px 32px 40px
    //    box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2)
    //    background: $neutrals8
    //    visibility: hidden
    //    opacity: 0
    //    transition: all .2s
    //    +dark
    //        background: $neutrals1
    //    &.active
    //        visibility: visible
    //        opacity: 1
    button {
        position: absolute;
        top: 0;
        left: 10px;
        bottom: 0;
        width: 42px;
        svg {
            fill: white;
            transition: fill .2s; }
        &:hover {
            svg {
                fill: $blue; } } }
    input {
        width: 100%;
        height: 40px;
        padding-left: 50px;
        background-color: $lightDark;
        color: white;
        border-radius: 6px;
        @include poppins;
        @include caption-2;
        transition: border-color .2s;
        @include placeholder {
            color: $neutrals4; }
        @include dark {
            border-color: $neutrals3;
            color: $neutrals8; }
        &:focus {
            border-color: $neutrals4; } } }
.dot {
    background-color: #ABABAB !important;
    width: 7px !important;
    height: 36px !important;
    border-radius: 0 !important; }
