@import "../../styles/helpers";

.container {
    @include c {
        flex-direction: column; }
    > div {
        @include c {
            width: 100%; } }
    .filterContainer {
        @include c {
            max-width: 400px;
            margin: 0 auto;
            padding-right: 0 !important; } } }
