@import "../../../styles/helpers";

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    background-color: #1A1A1A;
    border-radius: 6px;
    max-width: 280px;
    margin: 0 auto;
    margin-bottom: 40px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        transform: scale(1.02); } }

.cardContent {
    padding: 16px;
    padding-bottom: 0px;
    width: 100%; }


.cardActions {
    display: flex;
    justify-content: space-between;
    background-color: #161616;
    width: 100%;
    padding: 2px 16px;
    border-radius: 0 0 6px 6px; }

.cardActionsLeft {
    display: flex; }

.cardActionsRight {
    display: flex; }



.coverPlayBtn {
    display: none;
    opacity: 0.4;
    transition: opacity 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
        opacity: 0.7; } }


.imageWrapper {
    cursor: pointer;
    width: 100%;
    height: 0;
    margin-bottom: 1em;
    padding-bottom: 100%;
    position: relative;
    .image {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        border-radius: 4px;
        height: 100%;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover .imageOverlay {
            background-color: rgba(0,0,0,0.5);
            transition: background 0.3s cubic-bezier(.25,.8,.25,1); } }
    .favorite {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 10px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center; }
    &:hover .coverPlayBtn {
        display: flex; } }

.imageOverlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px; }

.title {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 24px;
    height: 50px;
    overflow-y: hidden;
    opacity: .8;
    &:hover {
        opacity: 1; } }

.owner {
    font-size: 14px;
    padding-bottom: 10px;
    color: rgba(255,255,255,0.5);
    font-weight: 500; }

.priceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px; }

.priceCaption {
    text-transform: uppercase;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    letter-spacing: 0.08em;
    color: #A0A0A0; }

.price {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center; }

.token {
    font-size: 15px;
    color: rgba(255,255,255,0.7);
    padding-left: 5px; }

.buttonContainer {
    background-color: $lightDark;
    margin-left: 5px;
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px;
    button {
        width: 50%;
        padding: 12px 0 10px 0;
        font-weight: bold; }
    .buy {
        border-radius: 30px;
        background-color: whitesmoke;
        color: black; }
    .buy:hover {
        border-radius: 30px;
        background-color: white;
        color: #1a1a1a; } }

.likeWrapper, .repostWrapper {
    display: flex;
    align-items: center;
    margin-right: 1em;
    cursor: pointer;
    height: 3em;
    width: 3.75em; }

.shareWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    height: 3em;
    width: 3.75em; }

.likeIconWrapper, .repostIconWrapper {
    position: relative;
    margin-right: .5em; }

.shareIconWrapper {
    position: relative; }

.likeIconInteract, .repostIconInteract, .shareIconInteract {
    display: none;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(.25,.8,.25,1);
    top: 0px;
    margin: -7px;
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%; }

.likeIcon, .repostIcon, .shareIcon {
    width: 16px;
    height: auto;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1); }

.repostIcon {
    margin-top: -2px; }

.shareIcon {
    padding-bottom: 0; }

.likeCount, .repostCount {
    color: #585858;
    font-weight: 500;
    transition: color 0.3s cubic-bezier(.25,.8,.25,1);
    font-family: 'Work Sans';
    font-size: 14px; }


/* Coloring */

.likeWrapper {
    &:hover .likeIcon {
        fill: rgb(249, 24, 128);
        fill-opacity: 1; }
    &:hover .likeCount {
        color: rgb(249, 24, 128); }
    &:hover .likeIconInteract {
        display: block;
        opacity: .1; }
    &:active .likeIconInteract {
        display: block;
        opacity: .3; } }

.repostWrapper {
    &:hover .repostIcon {
        fill: rgb(0,186,124);
        fill-opacity: 1; }
    &:hover .repostCount {
        color: rgb(0,186,124); }
    &:hover .repostIconInteract {
        display: block;
        opacity: .1; }
    &:active .repostIconInteract {
        display: block;
        opacity: .3; } }

.shareWrapper {
    &:hover .shareIcon {
        fill: rgb(0,186,124);
        fill-opacity: 1; }
    &:hover .shareIconInteract {
        display: block;
        opacity: .1; }
    &:active .shareIconInteract {
        display: block;
        opacity: .3; } }

.likeIconInteract {
    background: rgba(249, 24, 128, 1); }

.repostIconInteract {
    background: rgba(0, 186, 124, 1); }

.shareIconInteract {
    background: rgba(0, 186, 124, 1); }
