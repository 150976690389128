.app-derivative-card {
  width: 260px;
  height: 88px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  box-sizing: border-box;

  .derivative-card-label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0;
  }

  .derivative-card-value {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0;
  }
}
