.app-text-label {
  margin-top: 20px;

  .text-label {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 4px;
  }

  .text-value {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 4px;
  }

  .text-label {
    font-weight: 600;
  }

  &.right {
    .text-label, .text-value {
      text-align: right;
    }
  }

  &.country {
    .text-label, .text-value {
      margin-bottom: 2px;
    }

    .text-value {
      color: rgba(221, 221, 221, 0.3);
    }
  }
}
