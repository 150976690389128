.app-details-layout {
  // width: 1180px;
  // padding: 4px 20px 4px 0;
  box-sizing: border-box;
  padding-right: 40px;

  @media only screen and (max-width: 990px) {
    width: 100%;
    padding: 4px 20px;
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
  }
}
