.app-month-status {
  min-width: calc(100%/3 - 8px);
  padding: 16px 12px;
  margin: 16px 0;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;


.status-label {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 4px;
}

  .status-value {
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 4px;
  }

  .status-percent {
    display: flex;
    align-items: center;

    .percent-value {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin: 0;
      margin-right: 4px;

      &.up {
        color: #00B312;
      }

      &.down {
        color: #F91880;
      }
    }
  }
}
