.app-album-layout {
  min-width: 380px;
  max-width: 380px;
  height: 380px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.layout-hero {
  background: linear-gradient(
    180deg, rgba(255, 255, 255, 0.02) 100%, rgba(53, 14, 107, 0) 100%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.layout-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
