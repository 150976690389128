@font-face {
  font-family: ObjectSans;
  src: url(../../assets/fonts/PPObjectSans-Regular.otf);
}

body {
  font-size: 16px;
}

.h1 {
  font-family: ObjectSans;
}
 
.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "DM Sans";
}

button.btn-landing {
  background: transparent;
  border: 1px solid white;
  font-family: "DM Sans";
  padding: 8px 16px;
  font-size: 15px;
  transition: 0.2s ease;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 130px;
  letter-spacing: 5%;
  &:hover {
    background: white;
    color: black;
  }
}

.landing_hero {
  width: 100%;
}

.landing_hero_slide {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  .mb-text {
    margin-bottom: 1.5em;
  }
  .landing_hero_slide_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    .landing_hero_slide_container .h1 {
      font-size: 88px;
      font-weight: 600;
      line-height: 1;
      font-family: ObjectSans;
    }
  }
}

.landing_hero_slide1 {
  background-color: #1e1f24;
}

.landing_body {
  width: 100%;
  max-width: 1200px;
  h3 {
    font-family: ObjectSans;
    font-size: 38px;
    line-height: 1.25;
    font-weight: 600;
  }
  h5 {
    font-family: ObjectSans;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 1.25;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.2;
  }
  .mb-text {
    margin-bottom: 1em;
  }
}

.reep-intro {
  margin-bottom: 60px;
  .reep-intro_desc {
    display: flex;
    align-items: center;
    .reep-intro_desc_text {
      margin-bottom: 2.5em;
      width: 100%;
      .reep-intro_desc_text_h3 {
        margin-bottom: 32px;
        font-family: ObjectSans;
        height: 60px;
      }
      p {
        opacity: 0.5;
        font-size: 16px;
      }
    }
    .reep-intro_desc_btn {
      button {
        margin-right: 1em;
      }
    }
  }
}

.landing_stats_card {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  padding: 18px 24px;
  padding-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  .number {
    font-size: 30px;
    font-weight: 600;
    font-family: ObjectSans;
  }
  p {
    color: rgba(255, 255, 255, 0.4);
  }
}

.landing_body_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  grid-auto-rows: minmax(380px, auto);

  .grid_daniel-allen {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 73.96%,
        #000000 100%
      ),
      url(https://pbs.twimg.com/profile_images/1443102914461847557/5zuZXWdo_400x400.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    grid-column: 1;
    border-radius: 6px;

    // Content positioning
    padding: 40px;
    display: flex;
    justify-content: left;
    align-items: flex-end;

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.25;
      margin-bottom: 0.5em;
      font-family: ObjectSans;
    }
  }
  .grid_curve-shingo {
    grid-column: 2/4;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 78%,
        rgba(0, 0, 0, 1) 150%
      ),
      url(../../assets/images/landing-3-curve-shingo.png);
    background-size: cover;
    background-repeat: no-repeat;
    // Content positioning
    padding: 40px;
    h4 {
      font-weight: 600;
      font-size: 38px;
      line-height: 1.25;
      margin-bottom: 0.5em;
      font-family: ObjectSans;
    }
  }
  .grid_masterworks-bach {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
      url(../../assets/images/landing-3-bach.png);
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 1/4;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    // Content positioning
    padding: 48px;
    display: flex;
    justify-content: left;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 38px;
      line-height: 1.25;
      margin-bottom: 0.5em;
      font-family: ObjectSans;
    }
  }
  .grid_wildstyle-wildebeats {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
      url(../../assets/images/landing-3-wildstyle.png);
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 1/4;
    border-radius: 6px;

    // Content positioning
    padding: 48px;
    display: flex;
    justify-content: right;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 38px;
      line-height: 1.25;
      margin-bottom: 0.5em;
      font-family: ObjectSans;
    }
  }
  .mb-text {
    margin-bottom: 1.5em;
  }
}

.feature-skeleton {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  padding: 18px 24px;
  padding-bottom: 20px;
  height: 400px;
}

.feature_content {
  min-width: 50%;
  max-width: 400px;
  .feature_content_stats {
    display: flex;
    margin-bottom: 2em;
    .feature_content_stats_card {
      background: rgba(255, 255, 255, 0.08);
      border-radius: 4px;
      margin-right: 8px;
      min-width: 120px;
      padding: 10px 16px;
      //   backdrop-filter: blur(2px);
      p {
        text-transform: uppercase;
        opacity: 0.4;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.2px;
      }
      .number {
        font-size: 16px;
        font-weight: 500;
        font-family: ObjectSans;
      }
    }
  }
}
