.app-layout-info {
  width: 190px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  margin: 10px 10px 10px 0;
  background: #1A1A1A;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
}

.app-null-layout-info {
  width: 0;
}
