* {
    box-sizing: border-box;
    color: white;
}

var{
    --primary: #16EBAE;
}

@font-face {
    font-family: Montserrat;
}

body{
    font-family: 'Work Sans';
    background-color: #0e0e0e;
}

.loading {
    display          : inline-block;
    width            : 50px;
    height           : 50px;
    border           : 5px solid rgba(#f8b94b, 0.2);
    border-radius    : 50%;
    border-top-color : #f8b94b;
    animation        : spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left             : calc(50% - 15px);
    top              : calc(50% - 15px);
    position         : fixed;
    z-index          : 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
