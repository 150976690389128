@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');


:root {
  --grey: rgba(255,255,255,0.25);
  --white50: rgba(255,255,255,0.5);

}
.topbanner{
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0) 60%, rgba(20,20,20,1) 100%);
    width: 100%;
    height: 358px;
    z-index:55;
  /*  background-image: url('https://images.pexels.com/photos/315191/pexels-photo-315191.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'); */
}
.num_div{
  width:88px;
}
.withte80{
  color: rgba(255,255,255,0.8);
}
.num{
    font-size: 20px;

}
.grey{
    color: var(--grey);
    font-size: 14px;
    font-family: 'Work Sans';
}
.desc{
    font-size: 14px;
}
.follow_but{
  display: block;
  background-color:  rgba(255,255,255,0.08);
  width:240px;
  height:40px;
  color:#fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 25px;

}
.follow_but a svg{
  margin-right: 5px;
}

.feed_card{
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  max-width:580px;
  padding:10px 20px;
  margin:12px 0;
}
.card_head{
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.label{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 120px;
  height: 20px;
  border-radius: 59px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color:var(--white50);
}
.card_name{
  margin-right: 5px;
}
.card_day{
  color:var(--grey);
  font-size: 13px;
  font-weight: 400;
}
.icon{
  padding:8px;
  margin:12px 21px 12px 0;
}
.icon svg{
  margin-right: 5px;
}
.icon span{
  color:var(--white50);
  font-size: 14px;
  font-weight: 500;
}
.comment{
  display: flex;
  padding:8px 0;
}
.write_comment{
  height:36px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 50px;
  padding:10px 16px;
  display: flex;
  align-items: center;


}
.write_comment svg{
  margin-right: 7px;
}
.write_comment p{
  color:var(--white50);
  font-weight: 400;
  font-size:14px;
}
@media (max-width:578px){
  .profile_top{
    padding:0 20px;
  }
  .profile_bottom{
    padding:0 30px;
  }
  .follow_but{
    margin-bottom: 0;
  }
}

.artist-banner {
  background-size: cover !important;
}
