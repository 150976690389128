.app-showmore-button {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  cursor: pointer;

  .button-text {
    color: rgba(255, 255, 255, 0.25);
    background-color: rgba(255, 255, 255, 0);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0;
    margin-right: 10px;
  }

  .arrow-icon {
    transform: rotate(180deg);
    transition: transform 0.1s linear;

    &.show {
      transform: rotate(0deg);
      transition: transform 0.1s linear;
    }
  }
}
