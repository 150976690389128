.app-nft-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;

  .nft-detail {
    width: 100%;
    min-width: 200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    box-sizing: border-box;

    .nft-layout-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      @media only screen and (max-width: 400px) {
        display: block;

        .nft-refresh-button {
          padding: 10px 0;
          justify-content: flex-end;
        }
      }

      .nft-title {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin: 0;
        margin-right: 8px;
      }

      .nft-refresh-button {
        display: flex;
        align-items: center;
        cursor: pointer;

        .refresh-button-text {
          color: rgba(255, 255, 255, 0.25);
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          margin: 0;
          margin-left: 10px;
        }
      }
    }

    .nft-detail-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      .label, .value {
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        line-height: 16px;
        margin: 0;
      }

      .label {
        font-weight: 600;
      }

      .value-group {
        display: flex;
        align-items: center;

        .value {
          margin-right: 10px;
        }
      }
    }

    .showmore-button {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;

    .nft-detail {
      width: 100%;
    }
  }
}
