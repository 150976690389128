.app-creator-card {
  min-width: 200px;
  padding: 20px 10px 20px 0;
  box-sizing: border-box;
  margin-right: 40px;

  @media only screen and (max-width: 600px) {
    padding: 20px 10px;
    margin-right: 0;
    max-width: 300px;
  }

  .card-label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin: 0;
  }

  .card-avatar {
    padding: 24px 20px 18px 0;

    img {
      width: 100%;
    }
  }

  .creator-name {
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  .reviews-container {
    display: flex;
    align-items: center;
    margin: 11px 0 24px;

    .views-container {
      display: flex;
      align-items: center;
      margin-right: 18px;
    }

    .review-number {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      margin-left: 6px;
    }
  }
}
