.app-profile-tabs {
  .profile-tabs-header-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);

    .profile-tabs-header {
      max-width: 779px;
      margin: 0 auto;
  
      ul {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
  
        li {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .profile-tabs-header-item {
            cursor: pointer;
            padding-bottom: 20px;
            border-bottom: 3px solid transparent;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
  
            svg {
              margin-right: 10px;
              fill: rgba(255, 255, 255, 0.5);
            }
  
            &:hover,
            &.active {
              border-bottom-color: rgba(255, 255, 255, 0.5);
              color: rgba(255, 255, 255, 0.8);
  
              svg {
                fill: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
    }
  }

  .profile-tabs-body {
    max-width: 779px;
    margin: 0 auto;
    padding: 16px 0 40px;
    box-sizing: border-box;
  }
}
